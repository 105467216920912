import React from 'react';

import Panel from 'muicss/lib/react/panel';

const FormResult = ({ result }) => {

	if(typeof result === 'undefined' || result === null) {
		return null;
	}

	const errors = <ul className="mui-list--unstyled">
		{ Object.keys(result).map((key, i) => {
			const cssClass = (key !== 'success' ? 'danger' : '');
			return (
				<li key={i} className={`mui--text-${cssClass}`}>
					{result[key]}
				</li>
			)
		}) }
	</ul>

	return <Panel>{errors}</Panel>

}

export default FormResult;
