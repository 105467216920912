export const highlightPhrase =  (string, phrase) => {
	if(!phrase.length) {
		return string;
	} else {
		return string.replace(new RegExp(phrase, 'gi'), match => {
        	return `<span class="mui--bg-primary-light">${match}</span>`;
    	});
	}
}

export const scroll2Top = () => {
	document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

export const callAPI = ({ uri, method='GET', data={}, onNetErr=null }) => {
	const headers = {};
	const config = { method: method, cache: 'no-store' };
	const authToken = localStore('authToken');
	if(Object.keys(data).length) {
		config.body = JSON.stringify(data);
		headers['Accept'] = 'application/json';
		headers['Content-Type'] = 'application/json';
	}
	if(authToken) {
		headers['Authorization'] = authToken;
	}
	config.headers = headers;
	return fetch(process.env.REACT_APP_API_URL + uri, config)
		.then(response => response.text())
    .then(text => {
      try {
        return JSON.parse(text);
      } catch(error) {
        console.log('FETCH_JSON_ERR', error);
        onNetErr('Connection error!');
      }
    });
}

export const localStore = (key, val='') => {
	if(val === '') {
		return localStorage.getItem(key);
	} else if(val === null) {
		localStorage.removeItem(key);
	} else {
		localStorage.setItem(key, val);
	}
}
