import React, { Component } from 'react';
import CryptoJS from 'crypto-js';

import FormResult from './FormResult';
import { callAPI, localStore } from './functions';

import Col from 'muicss/lib/react/col';
import Button from 'muicss/lib/react/button';
import Container from 'muicss/lib/react/container';
import Form from 'muicss/lib/react/form';
import Input from 'muicss/lib/react/input';
import Row from 'muicss/lib/react/row';

class LoginForm extends Component {

	constructor() {
		super();
		this.state = {
			login: localStore('username') || '',
			password: '',
			loginResult: null,
			disableSubmit: true
		}
	};

	onFieldChange = event => {
		this.setState({ [event.target.name]: event.target.value }, () => {
			this.checkIfDisableSubmit();
		});
	};

	checkIfDisableSubmit = () => {
		const { login, password } = this.state;
		this.setState({ 
			disableSubmit: (login.length < 8 || password.length < 8)
		})
	};

	onFormSubmit = event => {
		event.preventDefault();
    const { login, password } = this.state;
		const { onNetErr } = this.props;
		callAPI({
			uri: '/user/login',
			method: 'POST',
			data: {
				login: login,
				password: CryptoJS.enc.Hex.stringify(CryptoJS.SHA1(password))
			},
      onNetErr
		}).then(data => {
      if(data) {
			 this.setState({ loginResult: data.result });
			 if(data.result.success) {
				this.props.onUserLogin(data.token, data.username);
			 }
      }
		}).catch(error => onNetErr(error));
	};

	render() {
		let { login, password, loginResult } = this.state;
		if(this.props.loginResult) {
			loginResult = this.props.loginResult;
		}
		return (
			<Container>
				<Row>
					<Col xs="12" sm="8" md="6" lg="4">
						<FormResult result={loginResult} />
						<Form onSubmit={this.onFormSubmit}>
							<Input
								type="text"
								name="login"
								value={login}
								onChange={this.onFieldChange}
								autoFocus={!login.length}
								maxLength="16"
								placeholder="Login" />
							<Input
								type="password"
								name="password"
								value={password}
								onChange={this.onFieldChange}
								autoFocus={login.length && !password.length}
								placeholder="Password" />
							<Button
								type="submit"
								variant="raised"
								color="accent"
								disabled={this.state.disableSubmit}>
									Login {login.length >= 6 ? `as ${login}` : ''}
							</Button>
						</Form>
					</Col>
				</Row>
			</Container>
		)
	};

}

export default LoginForm;