import React, { Component, Fragment } from 'react';

import { highlightPhrase } from './functions';

import Button from 'muicss/lib/react/button';

class QuoteItem extends Component {

	onClickKeyword = event => {
		event.preventDefault();
		const phrase = event.target.innerText;
		this.props.searchQuotes(phrase);
	};

	renderKeywords = (keywords, phrase) => {
		return keywords.split(', ').map((keyword, i) => {
			return (
				<li key={i}>
					<span
						onClick={this.onClickKeyword}
						dangerouslySetInnerHTML={{__html: highlightPhrase(keyword, phrase)}}>
					</span>
				</li>
			)
		})
	};

	render() {

		const { phrase, id, text, keywords, onClickEdit } = this.props;

		return (
			<Fragment>
				<strong className="mui--text-headline" dangerouslySetInnerHTML={{__html: highlightPhrase(text, phrase)}}></strong>
				<pre className="mui--text-body">
					<ul className="mui-list--unstyled">
						{this.renderKeywords(keywords, phrase)}
					</ul>
				</pre>
				<div className="mui--text-right">
					<span className={"mui--align-middle mui--text-dark-hint"}>#{id}</span>
					 &nbsp; 
					<Button
						variant="raised"
						size="small"
						className="mui--align-middle"
						onClick={onClickEdit}>edit</Button>
				</div>
			</Fragment>
		)

	};

}

export default QuoteItem;