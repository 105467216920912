import React, { Component } from 'react';

import Quote from './Quote';

import Panel from 'muicss/lib/react/panel';

class QuoteContainer extends Component {

	constructor() {
		super();
		this.state = {
			formActive: false
		}
	}; 

	toggleActive = () => {
		this.setState(prevState => ({
			formActive: !prevState.formActive
		}))
	};

	render() {

		const { phrase, quote, removeQuote, afterQuoteSave, searchQuotes } = this.props;

		const { formActive } = this.state;

		return (
			<Panel className={"quoteItem" + (formActive ? " active mui--z3" : "") }>
				<Quote {...quote}
					phrase={phrase}
					toggleActive={this.toggleActive}
					removeQuote={removeQuote}
					afterQuoteSave={afterQuoteSave}
					searchQuotes={searchQuotes} />
			</Panel>
		)

	};

}

export default QuoteContainer;
