import React, { Component } from 'react';

import Dropdown from 'muicss/lib/react/dropdown';
import DropdownItem from 'muicss/lib/react/dropdown-item';

class MainMenu extends Component {

	onClickReload = event => {
		this.props.submitSearch('');
	};

	onClickChangePassword = event => {
		this.props.toggleChangePasswordForm();
	};

	onClickLogout = event => {
		this.props.userLogout();
	};

	render() {
		const { username, className } = this.props;
		return (
			<Dropdown color="primary" alignMenu="right" className={`menu ${className}`} label="&#9776;">
				<DropdownItem onClick={this.onClickReload}>Reload</DropdownItem>
				<DropdownItem onClick={this.onClickChangePassword}>Change password</DropdownItem>
				<DropdownItem onClick={this.onClickLogout}>Log out {username}</DropdownItem>
			</Dropdown>
		)
	}

}

export default MainMenu;