import React, { Fragment } from 'react';

import Button from 'muicss/lib/react/button';

const ConfirmDialog = ({ text, show, onClickYes, onClickNo, children }) => {
	
	return show ? <Fragment>
		<p className="mui--text-subhead mui--text-danger">{text}</p>
		<Button type="button" variant="raised" color="danger" className="btnDeletebtn lighter" onClick={onClickYes}>
			<span className="mui--hidden-xs">Yes</span> ✔ 
		</Button>
		<Button type="button" variant="raised" className="btnCancel" onClick={onClickNo}>
			<span className="mui--hidden-xs">No</span> × 
		</Button>
	</Fragment> : children

}

export default ConfirmDialog;
