import React, { Component } from 'react';
import CryptoJS from 'crypto-js';

import FormResult from './FormResult';
import { callAPI } from './functions';

import Col from 'muicss/lib/react/col';
import Button from 'muicss/lib/react/button';
import Form from 'muicss/lib/react/form';
import Input from 'muicss/lib/react/input';
import Panel from 'muicss/lib/react/panel';
import Row from 'muicss/lib/react/row';

class ChangePasswordForm extends Component {

	constructor() {
		super();
		this.state = {
			password: '',
			passwordNew: '',
			passwordNewConfirm: '',
			formResult: null,
			disableSubmit: true
		}
	};

	componentDidMount() {
		this.checkIfDisableSubmit();
	}

	onFieldChange = event => {
		this.setState({ [event.target.name]: event.target.value }, () => {
			this.checkIfDisableSubmit();
		});
	};

	checkIfDisableSubmit = () => {
		const { password, passwordNew, passwordNewConfirm } = this.state;
		const disableFlag = password.length < 8 || passwordNew.length < 8 || passwordNewConfirm.length < 8;
		this.setState({ disableSubmit: disableFlag })
	};

	onFormSubmit = event => {
		event.preventDefault();
		const { password, passwordNew, passwordNewConfirm } = this.state;
		const stringify = CryptoJS.enc.Hex.stringify;
		const sha1 = CryptoJS.SHA1;
		callAPI({
			uri: '/user/password',
			method: 'PUT',
			data: {
				password: stringify(sha1(password)),
				'password_new': stringify(sha1(passwordNew)),
				'password_new_confirmation': stringify(sha1(passwordNewConfirm))
			}
		}).then(data => {
			this.setState({ formResult: data.result })
			if(data.result && data.result.success) {
				this.setState({
					password: '',
					passwordNew: '',
					passwordNewConfirm: '',
				})
			}
		}, error => { console.log("ERR:", error) });
	};

	render() {
		const { formResult, password, passwordNew, passwordNewConfirm } = this.state;
		const { username, toggleChangePasswordForm } = this.props;
		const formSuccess = formResult && formResult.success ? formResult.success : null;
		return (
			<Panel className="active mui--z3">
				<h2 className="mt0 mb5 mui--text-headline">New password for {username}</h2>
				<Row>
					<Col xs="12" sm="8" md="6" lg="5">
						<FormResult result={formResult} />
						<Form onSubmit={this.onFormSubmit}>
							<Input
								type="password"
								name="password"
								value={password}
								onChange={this.onFieldChange}
								autoFocus={!password.length}
								maxLength="16"
								placeholder="Current password"
								className={ formSuccess ? 'mui--hide' : '' } />
							<Input
								type="password"
								name="passwordNew"
								value={passwordNew}
								onChange={this.onFieldChange}
								autoFocus={password.length && !passwordNew.length}
								placeholder="New password (at least 8 characters)"
								className={ formSuccess ? 'mui--hide' : '' } />
							<Input
								type="password"
								name="passwordNewConfirm"
								value={passwordNewConfirm}
								onChange={this.onFieldChange}
								autoFocus={password.length && passwordNew.length && !passwordNewConfirm.length}
								placeholder="Confirm new password"
								className={ formSuccess ? 'mui--hide' : '' } />
							<div className="mui--clearfix">
								<Button
									type="submit"
									variant="raised"
									color="accent"
									disabled={this.state.disableSubmit}
									className={'mui--pull-left' + (formSuccess ? ' mui--hide' : '')}>
										<span className="mui--hidden-xs">SAVE</span> ✔ 
									</Button>
								<Button
									type="button"
									variant="raised"
									className="btnCancel mui--pull-right"
									onClick={toggleChangePasswordForm}>
										<span className="mui--hidden-xs">
											{ formSuccess ? 'OK' : 'cancel' }
										</span> { formSuccess ? '✔' : '×' }
								</Button>
							</div>
						</Form>
					</Col>
				</Row>
			</Panel>
		)
	};

}

export default ChangePasswordForm;