import React, { Component } from 'react';
import QuoteForm from './QuoteForm';
import QuoteItem from './QuoteItem';

class Quote extends Component {

	constructor() {
		super();
		this.state = { editMode: false }
	};

	onClickEdit = () => {
		this.setState({
			editMode: !this.state.editMode
		}, () => {
			this.props.toggleActive()
		})
	};

	render() {
		
		return this.state.editMode
			? <QuoteForm {...this.props}
				onClickEdit={this.onClickEdit}/>
			: <QuoteItem {...this.props}
				onClickEdit={this.onClickEdit}
				highlight={this.highlight} />

	};

}

export default Quote;
