import React from 'react';

import Button from 'muicss/lib/react/button';
import Spinner from './Spinner';
import QuoteContainer from './QuoteContainer';

const QuotesList = ({ quotes, summary, phrase, removeQuote, afterQuoteSave, searchQuotes, loading, loadMore }) => {

	return (
		<div>
			
			{quotes.length>0 && quotes.map(quote =>
				<QuoteContainer
					key={quote.id}
					phrase={phrase}
					quote={quote}
					removeQuote={removeQuote}
					afterQuoteSave={afterQuoteSave}
					searchQuotes={searchQuotes}
				/>
			)}

			<Spinner loading={loading} />

			{!loading && <div className="mui--text-center mui--text-caption">
				<p>{quotes.length} / {summary.total}</p>
				{ quotes.length < summary.total && <Button
					className="mb-30"
					onClick={ loadMore }
					variant="raised"
					color="accent">Load more ▼
				</Button> }
			</div> }
			
		</div>
	);

}

export default QuotesList;
