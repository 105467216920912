import React, { Component } from 'react';

import { callAPI } from './functions';
import ConfirmDialog from './ConfirmDialog';

import Button from 'muicss/lib/react/button';
import Form from 'muicss/lib/react/form';
import Input from 'muicss/lib/react/input';
import Textarea from 'muicss/lib/react/textarea';

class QuoteForm extends Component {

	constructor(props) {
		super();
		let { text='', keywords='' } = props;
		text = text.trim();
		this.textMaxLength = 296;
		this.state = {
			text: text,
			keywords: keywords,
			disableSubmit: keywords.length < 3 || text.length < 10,
			showConfirmDeleteDialog: false
		}
	};

	onChangeText = event => {
		let value = event.target.value.replace(/ +/g, ' ');
		if(value.length > this.textMaxLength) {
      value = value.substring(0, this.textMaxLength)
    }
		this.setState({ text: value }, () => {
			this.checkIfDisableSubmit();
		})
	};

	onChangeKeywords = event => {
		const value = event.target.value.replace(/ +/g, ' ');
		this.setState({ keywords: value }, () => {
			this.checkIfDisableSubmit();
		});
	};

	checkIfDisableSubmit() {
		const { text, keywords } = this.state;
		this.setState({ 
			disableSubmit: (keywords.length < 3 || text.length < 10)
		})
	};

	onSubmitForm = event => {
		event.preventDefault();
		let uri, method;
		const { text, keywords } = this.state;
		const { id, onClickEdit, afterQuoteSave } = this.props;
		if(id === 0) {
			// create
			uri = '/quotes';
			method = 'POST';
		} else {
			// modify
			uri = '/quotes/'+id;
			method = 'PUT';
		}
		callAPI({
			uri: uri,
			method: method,
			data: { text: text, keywords: keywords }
		})
    	.then(data => {
    		afterQuoteSave(data, id, onClickEdit);
    	});
	};

	onClickDelete = event => {
		this.setState({ showConfirmDeleteDialog: true });
	};

	onConfirmDelete = () => {
		const { id, onClickEdit, removeQuote } = this.props;
		callAPI({
			uri: '/quotes/'+id,
			method: 'DELETE'
		})
    	.then(data => {
    		onClickEdit();
    		removeQuote(id);
    	});
	};

	closeConfirmDeleteDialog = () => {
		this.setState({ showConfirmDeleteDialog: false })
	}

	render() {

		const { id, onClickEdit } = this.props;
		const { text, keywords, showConfirmDeleteDialog } = this.state;

		return (
			<Form onSubmit={this.onSubmitForm}>
				
        <Textarea
					name="text"
					value={text}
					placeholder={id ? 'Quote...' : 'New quote...'}
					onChange={this.onChangeText} autoFocus={id===0}
					disabled={showConfirmDeleteDialog}
          className="quoteForm" />

				<Input
					type="text"
					name="keywords"
					value={keywords}
					placeholder="Keywords..."
					onChange={this.onChangeKeywords}
					disabled={showConfirmDeleteDialog} />

				<input type="hidden" name="id" defaultValue={id} />

				<ConfirmDialog
					text="Are you sure to delete?"
					show={showConfirmDeleteDialog}
					onClickYes={this.onConfirmDelete}
					onClickNo={this.closeConfirmDeleteDialog}>
				
					<Button type="submit" variant="raised" color="accent" className="btnSave" disabled={this.state.disableSubmit}>
						<span className="mui--hidden-xs">SAVE</span> ✔ 
					</Button>

					<Button type="button" variant="raised" className="btnCancel" onClick={onClickEdit}>
						<span className="mui--hidden-xs">cancel</span> × 
					</Button>

          <span className="chars-total">
            <span className={this.textMaxLength - text.length < 10 ? 'mui--text-danger' : ''}>{text.length}</span> / {this.textMaxLength}
          </span>

					{id ? <Button
							type="button"
							variant="raised"
							color="danger"
							className="btnDelete lighter mui--pull-right"
							onClick={this.onClickDelete}>
								<span className="mui--hidden-xs">DELETE</span> ✘ 
							</Button>
					 : ''}

				</ConfirmDialog>
			</Form>
		)

	};

}

export default QuoteForm;