import React, { Component } from 'react';

import Container from 'muicss/lib/react/container';
import Form from 'muicss/lib/react/form';
import Input from 'muicss/lib/react/input';

class SearchForm extends Component {
	
	constructor() {
		super();
		this.state = {
			value: '',
			lastValue: ''
		}
	};

	onClick = event => {
		event.target.select();
	};

	onChange = event => {
		this.setState({
			value: event.target.value
		})
	};

	onSubmit = event => {
		event.preventDefault();
		this.props.onSubmitSearch(this.state.value);
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		if(nextProps.phrase !== prevState.lastValue) {
			return { 
				lastValue: nextProps.phrase,
				value: nextProps.phrase
			};
		}
		return null;
	};

	render() {
		const { value } = this.state;
		return (
			<Form
				onSubmit={this.onSubmit}
				className="fixed"
				id="searchForm">
					<Container>
						<Input
							type="text"
							name="phrase"
							placeholder="Search..."
							value={value}
							onClick={this.onClick}
							onChange={this.onChange} />
					</Container>
			</Form>
		)
	};

}

export default SearchForm;
