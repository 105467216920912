import React, { Component, Fragment } from 'react';

import Appbar from 'muicss/lib/react/appbar';
import Button from 'muicss/lib/react/button';
import Container from 'muicss/lib/react/container';
import Panel from 'muicss/lib/react/panel';

import { callAPI, localStore, scroll2Top } from './functions';

import ChangePasswordForm from './ChangePasswordForm';
import LoginForm from './LoginForm';
import MainMenu from './MainMenu';
import SearchForm from './SearchForm';
import QuoteForm from './QuoteForm';
import QuotesList from './QuotesList';

class App extends Component {

	constructor() {
	 	super();
	 	this.state = {
	 		userAuth: false,
	 		authToken: '',
	 		loginResult: null,
      netErr: null,
	 		username: '',
	 		phrase: '',
	 		quotes: [],
	 		summary: { total: 0, nextPage: 1 },
	 		quotesLoading: false,
	 		disableAddQuoteBtn: false,
	 		changePasswordFormVisible: false
	 	}
	};

	componentDidMount() {
		// autologin - token from localStorage
		const token = localStore('authToken');
		if(token) {
			const username = localStore('username');
			this.onUserLogin(token, username);
		}
	};

	onUserLogin = (token, username) => {
		localStore('authToken', token);
		localStore('username', username);
		this.setState({
			userAuth: true,
			authToken: token,
			loginResult: null,
			username: username
		});
		this.requestQuotes(1);
	};

	userLogout = () => {
		callAPI({ uri: '/user/logout' });
		this.setState({
			userAuth: false,
			authToken: ''
		});
		localStore('authToken', null);
	};

	renderLoginForm = () => <LoginForm
    onNetErr={this.onNetErr}
		onUserLogin={this.onUserLogin}
		loginResult={this.state.loginResult} />;

  onNetErr = error => {
    error = error.toString();
    const online = window.navigator.onLine;
    this.setState({ netErr: online ? error : 'Connection error!' });
    if(!online) {
      let intvl = setInterval(() => {
        if(window.navigator.onLine) {
          this.closeNetErr();
          clearInterval(intvl);
        }
      }, 1000);
    }
  };

  closeNetErr = () => {
    this.setState({ netErr: null });
  };

	onSubmitSearch = phrase => {
		this.setState({ phrase: phrase }, () => {
			this.requestQuotes(1);
		});
	};

	toggleChangePasswordForm = () => {
		this.setState({ changePasswordFormVisible: !this.state.changePasswordFormVisible });
	};

	requestQuotes = (forcePage=0) => {
		const page = forcePage>0 ? forcePage : this.state.summary.nextPage;
		const newState = { quotesLoading: true };
		if(page === 1) {
			newState.quotes = [];
		}
		this.setState(newState, () => {
			callAPI({ uri: '/quotes?phrase='+this.state.phrase+'&page='+page })
	    		.then(data => {
	    			if(typeof data.result !== 'undefined' && data.result.error) {
		    			this.setState({
	    					userAuth: false,
	    					quotesLoading: false,
	    					loginResult: data.result
		    			})
		    		} else {
		    			// console.log(data);
		    			let quotes = data.items.data;
		    			if(page > 1) {
		    				const stateQuotes = this.state.quotes;
		    				stateQuotes.push(...quotes)
		    				quotes = stateQuotes;
		    			}
		    			this.setState(prevState => ({
		    				quotes: quotes,
		    				summary: {
		    					total: data.total,
		    					nextPage: data.items.current_page+1
		    				},
		    				quotesLoading: false
		    			}));
		    		}
	    		}, error => { console.log("ERR:", error) });
		});
	};

	loadMore = () => {
		this.requestQuotes();
	};

	onClickAddQuote = event => {
		event.preventDefault();
		this.setState({ disableAddQuoteBtn: true });
		scroll2Top();
	};

	afterQuoteSave = (data, id, callback) => {
		if(id === 0) {
			// add quote
			let quotes = this.state.quotes;
			quotes.unshift(data);
			this.setState({ quotes: quotes }, () => {callback()});
		} else {
			// edit quote
			this.state.quotes.some((quote, i) => {
				if(quote.id === data.id) {
					let quotes = this.state.quotes;
					quotes[i].text = data.text;
					quotes[i].keywords = data.keywords;
					quotes[i].hash = data.hash;
					this.setState({ quotes: quotes }, () => {callback()});
					return true; // break
				}
				return false;
			})
		}
	};

	onCloseAddFormQuote = () => {
		this.setState({ disableAddQuoteBtn: false });
	};

	removeQuote = id => {
		this.setState({
			quotes: this.state.quotes.filter(quote => quote.id !== id)
		});
	};

	renderContent = () => {

		const { username, quotesLoading, phrase, quotes, summary,
			disableAddQuoteBtn, changePasswordFormVisible } = this.state;

		return (
			<Container>

				{ changePasswordFormVisible && <ChangePasswordForm
					username={username}
					toggleChangePasswordForm={this.toggleChangePasswordForm} /> }
				
				<SearchForm
					phrase={phrase}
					onSubmitSearch={this.onSubmitSearch}
				/>

				{ disableAddQuoteBtn && <Panel className="active mui--z3">
					<QuoteForm
						id={0}
						afterQuoteSave={this.afterQuoteSave}
						onClickEdit={this.onCloseAddFormQuote}
					/>
				</Panel> }

				<QuotesList
					phrase={phrase}
					quotes={quotes}
					summary={summary}
					removeQuote={this.removeQuote}
					afterQuoteSave={this.afterQuoteSave}
					searchQuotes={this.onSubmitSearch}
					loading={quotesLoading}
					loadMore={this.loadMore}
				/>

				<Button
					className="addQuoteBtn mui--text-headline mui--z2"
					onClick={this.onClickAddQuote}
					disabled={disableAddQuoteBtn}
					variant="fab"
					color="accent">+
				</Button>

			</Container>
		)

	};

	render() {
		
    const { userAuth, username, netErr } = this.state;

		return (
		  	<Fragment>

          <Appbar className="fixed">
  					<Container className="mui--clearfix">
  						
              <h1 className="mui--pull-left">SV Quotes</h1>
  						
              { userAuth && <MainMenu
  							className="mui--pull-right"
  							username={username}
  							submitSearch={this.onSubmitSearch}
  							toggleChangePasswordForm={this.toggleChangePasswordForm}
  							userLogout={this.userLogout} /> }

  					</Container>
				  </Appbar>

				  { userAuth ? this.renderContent() : this.renderLoginForm() }

          { netErr &&
            <Panel
              className="net-error mui--text-button mui--bg-danger mui--text-white"
              onClick={this.closeNetErr}
            >
              {netErr}
            </Panel> }
			
			</Fragment>
		)

	};

}

export default App;
